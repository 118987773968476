<template>
  <div class="all-msg-page">
    <div class="search-main">
      <div class="search-item">
        <span class="title">风险等级：</span>
        <div>
          <el-select v-model="searchObj.risk" placeholder="请选择风险等级" size="small" clearable @change="toSearch"
            style="width: 180px;">
            <el-option v-for="item in riskOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item select-time">
        <span class="title">选择时间：</span>
        <div>
          <el-select v-model="searchObj.time" placeholder="请选择时间" size="small" clearable @change="toSearch"
            style="width: 180px;">
            <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="content-main" v-loading="pageLoading">
      <template v-if="listData.length > 0">
        <div v-for="(item, index) in listData" :key="index" class="list-item">
          <div class="enterprise-name">
            <span>
              {{ item.enterpriseName }}
            </span>
          </div>
          <div v-for="(its, idx) in item.enterpriseSearchMessageVOList" :key="idx"
            :class="{ 'msg-list-item': true, 'last-item': item.enterpriseSearchMessageVOList.length === idx + 1 }">
            <div class="msg-title">
              <span>
                {{ its.detailCategory }}
              </span>
              <span style="color: #999999">
                {{ its.detailDate }}
              </span>
            </div>
            <div class="msg-content">
              <div class="content-item" v-for="(it, id) in its.detailJson" :key="id">
                <div class="item-label">{{ it.label }}：
                </div>
                {{ it.value }}
              </div>
              <div class="risk-main">
                <div class="risk-label"
                  :style="{ 'background': colorDict[its.riskLabel].bgColor, 'color': colorDict[its.riskLabel].color, }">
                  {{ riskLabelDict[its.riskLabel] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <el-empty :image-size="120"></el-empty>
      </template>
    </div>
    <div class="pagina-main">
      <el-pagination background layout="total, sizes, prev, pager, next, jumper" :total="total"
        :page-sizes="[10, 20, 50, 100]" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as api from '@/api'
import { debounce } from 'lodash'
let updateTimer = null
export default {
  data() {
    return {
      riskOptions: [
        {
          label: '正向',
          value: '2',
        },
        {
          label: '提示',
          value: '1',
        },
        {
          label: '低风险',
          value: '3',
        },
        {
          label: '中风险',
          value: '4',
        },
        {
          label: '高风险',
          value: '5',
        }
      ],
      timeOptions: [
        {
          label: '今日',
          value: '1',
        }, {
          label: '近一周',
          value: '2',
        }, {
          label: '近两周',
          value: '3',
        }, {
          label: '近一个月',
          value: '4',
        }, {
          label: '近半年',
          value: '5',
        }
      ],
      colorDict: {
        1: {
          bgColor: '#E7F4EB',
          color: '#19A058 ',
        },
        2: {
          bgColor: '#DFECFF',
          color: '#4088EB ',
        },
        3: {
          bgColor: '#FDF1E1',
          color: '#FF8321 ',
        },
        4: {
          bgColor: '#F8EAF3',
          color: '#E23BB8',
        },
        5: {
          bgColor: '#FFEAE8',
          color: '#FF2000',
        },
      },
      riskLabelDict: {
        1: '提示',
        2: '正向',
        3: '低风险',
        4: '中风险',
        5: '高风险',
      },
      searchObj: {
        risk: '',
        time: '',
      },
      listData: [],
      total: 0,
      pageNo: 1,
      pageSize: 10,
      pageLoading: false,
    }
  },
  methods: {
    toSearch() {
      this.pageNo = 1
      this.pageSize = 10
      this.getListData()
    },
    getListData() {
      this.pageLoading = true
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        enterpriseInfoId: this.$route.query.enterpriseId,
        riskLabel: this.searchObj.risk,
        timeSelect: this.searchObj.time,
      }
      api.riskMonitor.getEnterpriseSearchMessagePage(params).then(({ success, data }) => {
        if (success) {
          this.listData = data.data.records
          this.total = Number(data.data.total)
        }
        this.pageLoading = false
      }).catch(() => {
        this.pageLoading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getListData()
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getListData()
    },
  },
  mounted() {
    this.toSearch()
  },
  computed: {
    ...mapState(['userInfo'])
  },
}
</script>

<style lang="scss" scoped>
.all-msg-page {

  .search-main {
    padding: 0px Translate(20px);
    height: Translate(80px);
    border-bottom: Translate(16px) solid #f5f6f9;
    display: flex;
    align-items: center;

    .search-item {
      display: flex;
      align-items: center;

      .title {
        font-size: Translate(14px);
        color: #999999;
        margin-right: Translate(8px);
      }
    }

    .select-time {
      margin-left: Translate(30px);
    }
  }

  .content-main {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: Translate(24px) Translate(20px);

    .list-item {
      margin-bottom: Translate(30px);

      .enterprise-name {
        font-size: Translate(20px);
        color: #333333;
        font-weight: 500;
        margin-bottom: Translate(8px);
        display: flex;
        justify-content: space-between;

        .monitor-status {
          width: Translate(82px);
          height: Translate(28px);
          border-radius: Translate(4px);
          font-size: Translate(12px);
          display: flex;
          align-items: center;
          justify-content: center;

          &-blue {
            color: #4088EB;
            border: 1px solid #4088EB;
          }

          &-grey {
            color: #333333;
            border: 1px solid #D9D9D9;
          }

          .iconfont {
            font-size: Translate(12px);
            margin-right: Translate(4px);
          }
        }
      }

      .msg-list-item {
        padding-left: Translate(10px);
        box-sizing: border-box;
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          width: Translate(1px);
          height: calc(100% - Translate(5px));
          background-color: #EBEBEB;
          position: absolute;
          left: 0;
          bottom: Translate(-7px);
        }

        &::after {
          content: '';
          display: inline-block;
          width: Translate(6px);
          height: Translate(6px);
          border-radius: Translate(3px);
          // background-color: #EBEBEB;
          background-color: #D7D7D7;
          position: absolute;
          left: Translate(-3px);
          top: Translate(7px);
        }

        .msg-title {
          font-size: Translate(14px);
          color: #333333;
          font-weight: 500;
          margin-bottom: Translate(8px);
          display: flex;
          justify-content: space-between;
        }

        .msg-content {
          background: #F9F9F9;
          box-sizing: border-box;
          padding: Translate(18px) Translate(30px) Translate(18px) Translate(20px);
          font-size: Translate(14px);
          color: #333333;
          line-height: Translate(24px);
          position: relative;
          border-bottom: Translate(20px) solid #FFFFFF;

          .risk-main {
            height: 100%;
            position: absolute;
            right: Translate(30px);
            top: Translate(0px);
            display: flex;
            justify-content: center;
            align-items: center;

            .risk-label {
              border-radius: Translate(4px);
              width: Translate(64px);
              height: Translate(28px);
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: Translate(12px);
            }
          }

          .content-item {
            line-height: Translate(24px);
            color: #333333;
            font-size: Translate(14px);
            padding-right: Translate(80px);

            .item-label {
              color: #999999;
              display: inline-block;
            }

          }
        }
      }

      .last-item {
        .msg-content {
          border-bottom: 0px;
        }
      }

      .more-main {
        font-size: Translate(14px);
        color: #4088EB;
        cursor: pointer;
        margin-top: Translate(10px);
        margin-left: Translate(10px);
      }
    }
  }

  .pagina-main {
    display: flex;
    justify-content: end;
    padding-bottom: Translate(40px);
  }
}
</style>