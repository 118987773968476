<template>
  <div class="account_center_page">
    <div class="content">
      <div class="bread_crumbs"
        :style="`${$route.meta.toBack ? 'color: #4088EB;cursor: pointer;' : 'cursor: default;'}`"
        @click=" $route.meta.toBack && $router.go(-1)">
        {{ $route.meta.toBack ? '< 返回' : $route.meta.title }} </div>
          <div class="user_info">
            <div class="info_left">
              <img src="https://images.zzt.com.cn/ks/2023/05/11/avatar.png">
              <div>
                <p class="user_name">{{ userInfo.nickname }}</p>
                <p class="user_email">{{ userInfo.email }}</p>
              </div>
            </div>
            <div class="info_right">{{ userInfo.accountType == 0 ? '子账号' : '主账号' }}</div>
          </div>
          <div class="menu_box">
            <div class="left_menu">
              <el-menu :default-active="activeIndex" :default-openeds="openeds" @open="handleOpen" @close="handleClose">
                <el-submenu :index="item.path" v-for="(item, index) in menuList" :key="`submenu${index}`"
                  @click="handleClickMenu(item, index, $event)">
                  <template slot="title">
                    <div class="first-level-main">
                      <i :class="`iconfont ${item.icon}`" style="position: absolute;left: 24px;"></i>
                      <span class="first-level-text">{{ item.label }}</span>
                    </div>
                  </template>
                  <template v-for="(its, idx) in item.children">
                    <el-menu-item :index="its.path" @click="handleClickMenu(its, idx, $event)">
                      <template slot="title">
                        <div class="second-level-main">
                        {{ its.label }}
                      </div>
                      </template>
                    </el-menu-item>
                  </template>
                </el-submenu>
              </el-menu>
            </div>
            <div class="right_content">
              <router-view />
            </div>
          </div>
      </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      openeds: ['/accountCenter', '/commonFunctions', '/productEquity'],
      activeIndex: '/accountInformation',
      menuList: [
        {
          label: '账号中心',
          path: '/accountCenter',
          icon: 'icon-zhanghu',
          children: [
            {
              label: '账号信息',
              path: '/accountInformation',
            },
            // {
            //   label: '账号管理',
            //   path: '/accountManagement',
            // }
          ]
        },
        {
          label: '常用功能',
          path: '/commonFunctions',
          icon: 'icon-changyonggongneng',
          children: [
            {
              label: '我的名单',
              path: '/myList',
            },
            {
              label: '管理监控企业',
              path: '/monitorManage',
            },
            {
              label: '消息中心',
              path: '/msgCenter',
            }
            // {
            //   label: '报告中心',
            //   path: '/reportCenter',
            // },
            // {
            //   label: '下载中心',
            //   path: '/downloadCenter',
            // },
            // {
            //   label: '我的关注',
            //   path: '/myAttention',
            // }
          ]
        },
        // {
        //   label: '产品权益',
        //   path: '/productEquity',
        //   icon: 'icon-chanpinquanyi',
        // }
      ],
      // personalInfo: this.$store.state.userInfo
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClickMenu(item, index, e) {
      console.log(item, index, e)
      this.activeIndex = item.path
      if (this.$route.path == `/accountCenter${item.path}`) return false
      this.$router.push(`/accountCenter${item.path}`)
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name == 'AllocationList') {
          this.activeIndex = '/myList'
        } else if (to.name == 'EnterpriseAllMsg') {
          this.activeIndex = '/msgCenter'
        } else {
          this.activeIndex = to.path.replace('/accountCenter', '')
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.userInfo.accountType == 1) {
      //主账号
      this.menuList[0].children.push(
        {
          label: '账号管理',
          path: '/accountManagement',
        }
      )
    }
  },
}
</script>

<style lang='scss' scoped>
.account_center_page {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: Translate(60px);

  .content {
    width: Translate(1200px);

    .bread_crumbs {
      font-size: Translate(14px);
      color: #666666;
      text-align: left;
      height: Translate(44px);
      line-height: Translate(44px);
    }

    .user_info {
      height: Translate(100px);
      background: #ffffff;
      border-radius: Translate(5px);
      margin-bottom: Translate(20px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px Translate(20px) 0px Translate(30px);

      .info_left {
        display: flex;
        align-items: center;
        text-align: left;

        img {
          width: Translate(60px);
          height: Translate(60px);
          margin-right: Translate(16px);
        }

        .user_name {
          font-size: Translate(24px);
          color: #333333;
          font-weight: bold;
        }

        .user_email {
          font-size: Translate(14px);
          color: #666666;
          margin-top: Translate(8px);
        }
      }

      .info_right {
        font-size: Translate(18px);
        color: #999999;
      }
    }

    .menu_box {
      display: flex;
      justify-content: space-between;
      border-radius: Translate(5px);

      .left_menu {
        width: Translate(190px);
        min-height: Translate(482px);
        background: #ffffff;
        border-radius: Translate(5px);
      }

      .right_content {
        width: Translate(994px);
        // height: Translate(560px);
        height: auto;
        background: #ffffff;
        border-radius: Translate(5px);
      }
    }
  }
}

::v-deep .left_menu {
  .el-menu {
    .el-menu-item {
      min-width: auto;
    }

    .el-menu-item.is-active {
      background: #ecf5ff;
    }
  }
}
</style>